var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { Vue, Component, Prop } from "vue-property-decorator";
import { FileType } from "~/config/enum.config";
import SvgIcon from "~/components/common/svg-icon.vue";
var ViewAttach = /** @class */ (function (_super) {
    __extends(ViewAttach, _super);
    function ViewAttach() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.canViewTypes = __spreadArrays(FileType.PICTURE.value, FileType.MP3.value, FileType.MP4.value);
        _this.fileType = FileType;
        _this.dialog = {
            pdf: false,
            video: false,
            audio: false,
            img: false
        };
        return _this;
    }
    Object.defineProperty(ViewAttach.prototype, "extStr", {
        get: function () {
            var str = this.fileInfo.extensionName;
            if (str)
                str = str.toLowerCase();
            return str;
        },
        enumerable: false,
        configurable: true
    });
    ViewAttach.prototype.previewFile = function () {
        switch (true) {
            case this.isImage:
                this.dialog.img = true;
                break;
            case this.isVideo:
                this.dialog.video = true;
                break;
            case this.isMusic:
                this.dialog.audio = true;
                break;
            case this.isPdf:
                this.dialog.pdf = true;
                break;
        }
    };
    ViewAttach.prototype.onVideoClose = function () {
        var video = this.$refs.video;
        if (!video.paused)
            video.pause();
        this.dialog.video = false;
    };
    ViewAttach.prototype.onAudioClose = function () {
        var audio = this.$refs.audio;
        if (!audio.paused)
            audio.pause();
        this.dialog.audio = false;
    };
    Object.defineProperty(ViewAttach.prototype, "isImage", {
        get: function () {
            return FileType.PICTURE.value.includes(this.extStr);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ViewAttach.prototype, "isMusic", {
        get: function () {
            return FileType.MP3.value.includes(this.extStr);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ViewAttach.prototype, "isVideo", {
        get: function () {
            return FileType.MP4.value.includes(this.extStr);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ViewAttach.prototype, "isPdf", {
        get: function () {
            return this.extStr === "pdf";
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop()
    ], ViewAttach.prototype, "fileInfo", void 0);
    ViewAttach = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon
            }
        })
    ], ViewAttach);
    return ViewAttach;
}(Vue));
export default ViewAttach;
